import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const defaultDataValue = {
  name: null,
  type: null,
  value: null,
};

export default function AddLabelValueDialog(props) {
  const [data, setData] = useState(defaultDataValue);

  useEffect(() => {
    setData(defaultDataValue);
  }, [props.show]);

  useEffect(() => {
    setData((prv) => ({ ...prv, type: props.type }));
  }, [props.type]);

  useEffect(() => {
      props.onChange(data);
  }, [data]);

  return (
    <Dialog open={props.show}>
      <DialogContent sx={{ backgroundColor: "#B4ADA4" }}>
        <FormControl fullWidth sx={{ marginTop: "10px" }}>
          <InputLabel id="demo-simple-select-label">{props.type}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label={props.type}
            value={data.name}
            onChange={(e) =>
              setData((prv) => ({ ...prv, name: e.target.value }))
            }
          >
            {props.labels?.map((n) => {
              return <MenuItem value={n}>{n}</MenuItem>;
            })}
          </Select>
        </FormControl>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Value"
          fullWidth
          type="number"
          variant="standard"
          value={data.value}
          onChange={(e) =>
            setData((prv) => ({ ...prv, value: e.target.value }))
          }
        />
      </DialogContent>
      <DialogActions sx={{ backgroundColor: "#B4ADA4" }}>
        <Button variant="contained" onClick={props.close}>
          Cancel
        </Button>
        <Button variant="contained" color="success" onClick={props.add}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
