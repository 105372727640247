import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";

export default function DetailsDialog(props) {
  return (
    <>
      <Dialog open={props.show}>
        <DialogContent sx={{ backgroundColor: "#B4ADA4" }}>
          {props.accountType == "FuelCredit" && props.data.debit == null && (
            <>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Driver"
                fullWidth
                variant="standard"
                value={props.data.driver ?? ""}
              />
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Vehicle"
                fullWidth
                variant="standard"
                value={props.data.vehicle ?? ""}
              />
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Litre"
                fullWidth
                variant="standard"
                value={props.data.litre ?? ""}
              />
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Item Type"
                fullWidth
                variant="standard"
                value={props.data.itemType ?? ""}
              />
            </>
          )}
          <TextField
            sx={{ marginTop: "10px" }}
            label="Description"
            fullWidth
            autoFocus
            multiline
            rows={3}
            margin="dense"
            variant="standard"
            value={props.data.description ?? ""}
          />
        </DialogContent>
        <DialogActions sx={{ backgroundColor: "#B4ADA4" }}>
          <Button variant="contained" onClick={props.close}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
