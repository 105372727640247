import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";

export default function SetPassword(props) {
  const [showButton, setShowButton] = useState(false);
  const [passwords, setPasswords] = useState({
    pass: "",
    confirm: "",
  });

  const passwordChanged = () => {
    if (passwords.pass == passwords.confirm && passwords.pass.length >= 10) {
      props.onPasswordChanged(passwords.pass);
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  useEffect(() => {
    setShowButton(false);
  }, props.show);

  useEffect(() => {
    passwordChanged();
  }, [passwords.pass, passwords.confirm]);

  return (
    <Dialog open={props.show}>
      <DialogTitle sx={{ backgroundColor: "#B4ADA4" }}>
        <Typography>Set password for "{props.user}"</Typography>
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: "#B4ADA4" }}>
        <TextField
          autoFocus
          margin="dense"
          label="Password"
          fullWidth
          type="password"
          variant="standard"
          onChange={(e) =>
            setPasswords((prv) => ({ ...prv, pass: e.target.value }))
          }
        />
        <TextField
          autoFocus
          margin="dense"
          label="Confirm Password"
          fullWidth
          type="password"
          variant="standard"
          onChange={(e) =>
            setPasswords((prv) => ({ ...prv, confirm: e.target.value }))
          }
        />
        <Card sx={{ marginTop: "10px" }}>
          <CardContent>
            <Typography variant="h6">Password must contain</Typography>
            <Typography>Minimum 10 charecters</Typography>
            <Typography>Atleast one lower case alphabet</Typography>
            <Typography>Atleast one upper case alphabet</Typography>
            <Typography>Atleast one number</Typography>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: "#B4ADA4" }}>
        <Button variant="contained" onClick={props.close}>
          Cancel
        </Button>
        <Button
          disabled={!showButton}
          variant="contained"
          color="success"
          onClick={props.passwordSet}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
