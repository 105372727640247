import PaymentsIcon from "@mui/icons-material/Payments";
import TollIcon from "@mui/icons-material/Toll";
import { Typography, Box } from "@mui/material";

const noteIndicator = (value) => {
  return {
    type: "note",
    value: value,
    indicator: (
      <>
        <Box display="flex" flexDirection="row">
          <PaymentsIcon />
          <Typography>&nbsp;&nbsp;{value}</Typography>
        </Box>
      </>
    ),
  };
};

const coinIndicator = (value) => {
  return {
    type: "coin",
    value: value,
    indicator: (
      <>
        <Box display="flex" flexDirection="row">
          <TollIcon />
          <Typography>&nbsp;&nbsp;{value}</Typography>
        </Box>
      </>
    ),
  };
};

export const dinominations = [
  {
    type: "note",
    items: [
      noteIndicator(2000),

      noteIndicator(500),

      noteIndicator(200),

      noteIndicator(100),

      noteIndicator(50),

      noteIndicator(20),

      noteIndicator(10),

      noteIndicator(5),
    ],
  },
  {
    type: "coin",
    items: [
      coinIndicator(20),

      coinIndicator(10),

      coinIndicator(5),

      coinIndicator(2),

      coinIndicator(1),
    ],
  },
];
