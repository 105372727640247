import { Paper, Typography, Box, Grid, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { ApiGet, ApiPut } from "../../../services/ApiService";
import { useDispatch } from "react-redux";
import FuelPricesDialog from "./FuelPricesDialog";
import { ParseDate } from "../../../utils/DateTimeUtils";
import { pushMessage } from "../../../features/messages";
import { setTitle } from "../../../features/title";
import { CreateLinkTitle } from "../../../utils/TitleUtils";

export default function FuelPrices() {
  const today = dayjs(new Date());
  const shouldDisableDate = (date) => date > today;
  const [date, setDate] = useState(today);
  const [fuelTypes, setFuelTypes] = useState([]);
  const [fuelPrices, setFuelPrices] = useState([]);
  const dispatch = useDispatch();
  const [fuelPricesDialog, setFuelPricesDialog] = useState({
    show: false,
  });

  dispatch(
    setTitle(CreateLinkTitle("/Shift-Manager", "Shift Manager", "Fuel Prices"))
  );

  const getFuelTypes = async () => {
    var result = await ApiGet(dispatch, "FuelTypes?limit=100");
    if (result) {
      setFuelTypes((prv) => {
        const val = result.data.items;
        getFuelPrices(val);
        return val;
      });
    }
  };

  const getFuelPrices = async (fts) => {
    var result = await ApiGet(null, "FuelPrices/" + ParseDate(date));
    if (result) {
      for (let index = 0; index < fts.length; index++) {
        if (
          result.data.prices.filter((x) => x.fuelTypeId == fts[index].id)
            .length == 0
        ) {
        }
      }
      setFuelPrices(
        result.data.prices.map((n) => {
          var fuelType = fts.filter((x) => x.id == n.fuelTypeId)[0];
          return {
            id: n.fuelTypeId,
            name: fuelType?.name ?? n.fuelTypeName,
            price: n.price,
            color: fuelType?.color ?? "gray",
          };
        })
      );
    } else {
      setFuelPrices(fts);
      if (date == today) {
        setFuelPricesDialog({
          show: true,
        });
      }
    }
  };

  useEffect(() => {
    getFuelTypes();
  }, []);

  useEffect(() => {
    getFuelPrices(fuelTypes);
  }, [date]);

  const fuelPricesSave = async () => {
    for (let index = 0; index < fuelPrices.length; index++) {
      if (fuelPrices[0].price == null || fuelPrices[0].price == 0) {
        dispatch(
          pushMessage({
            text: "Please provide all fuel rates for the day.",
            type: "warning",
            show: true,
          })
        );
        return;
      }
    }

    var result = await ApiPut(dispatch, "FuelPrices/" + ParseDate(date), {
      prices: fuelPrices.map((n) => {
        return {
          fuelTypeId: n.id,
          fuelTypeName: n.name,
          price: n.price,
        };
      }),
    });

    if (result) {
      setFuelPricesDialog({ show: false });
    }
  };

  return (
    <>
      <FuelPricesDialog
        save={fuelPricesSave}
        onChange={(val) =>
          setFuelPrices((prv) => {
            var data = [];
            prv.forEach((element) => {
              if (element.id == val.id) {
                data.push(val);
              } else {
                data.push(element);
              }
            });
            return data;
          })
        }
        show={fuelPricesDialog.show}
        date={date}
        fuelPrices={fuelPrices}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid sx={{ backgroundColor: "#B4ADA4" }} container>
          <Grid item xs={6}>
            <StaticDatePicker
              defaultValue={date}
              shouldDisableDate={shouldDisableDate}
              sx={{
                width: "300px",
                backgroundColor: "#B4ADA4",
                margin: "2px",
              }}
              slotProps={{ actionBar: { actions: [] } }}
              onChange={(e) => setDate(e)}
            />
          </Grid>
          <Grid item xs={6} display={"flex"} justifyContent={"center"}>
            <Box
              sx={{ margin: "20px" }}
              display="flex"
              justifyContent={"center"}
              flexDirection="column"
              width={"300px"}
            >
              {fuelPrices.map((n) => {
                return (
                  <>
                    <Paper
                      sx={{
                        backgroundColor: n.color,
                        marginBottom: "10px",
                        padding: "10px",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "row",
                      }}
                    >
                      <Typography
                        fontWeight={"bold"}
                        fontSize={20}
                        color={"white"}
                      >
                        {n.name}&nbsp;-&nbsp;
                      </Typography>
                      <Typography
                        fontWeight={"bold"}
                        fontSize={20}
                        color={"white"}
                      >
                        {n.price}
                      </Typography>
                    </Paper>
                  </>
                );
              })}
              {ParseDate(today) == ParseDate(date) && <Button variant="contained" onClick={()=> setFuelPricesDialog({ show: true })}>Edit</Button>}
            </Box>
          </Grid>
        </Grid>
      </LocalizationProvider>
    </>
  );
}
