import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const defaultDataValue = {
  name: null,
  type: "Addition",
};

export default function AddLabelsDialog(props) {
  const [data, setData] = useState(defaultDataValue);

  useEffect(() => {
    setData(defaultDataValue);
  }, [props.show]);

  useEffect(() => {
    props.onChange(data);
  }, [data]);

  return (
    <Dialog open={props.show}>
      <DialogContent sx={{ backgroundColor: "#B4ADA4" }}>
        <FormControl fullWidth sx={{ marginTop: "10px" }}>
          <InputLabel id="demo-simple-select-label">Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Type"
            value={data.type}
            onChange={(e) =>
              setData((prv) => ({ ...prv, type: e.target.value }))
            }
          >
            <MenuItem value="Addition">Addition</MenuItem>
            <MenuItem value="Subtraction">Subtraction</MenuItem>
          </Select>
        </FormControl>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Label"
          fullWidth
          variant="standard"
          onChange={(e) => setData((prv) => ({ ...prv, name: e.target.value }))}
        />
      </DialogContent>
      <DialogActions sx={{ backgroundColor: "#B4ADA4" }}>
        <Button variant="contained" onClick={props.close}>
          Cancel
        </Button>
        <Button variant="contained" color="success" onClick={props.add}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
