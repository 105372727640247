import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CreateLinkTitle } from "../../utils/TitleUtils";
import { setTitle } from "../../features/title";
import { useParams } from "react-router-dom";
import { ApiGet, ApiPost } from "../../services/ApiService";
import AddEntryDialog from "./AddEntryDialog";
import { Button } from "@mui/material";
import DataTable from "../../components/data/DataTable";
import { convertLocalDateToUTCDate } from "../../utils/DateTimeUtils";
import DetailsDialog from "./DetailsDialog";

const columns = [
  { id: "entryId", label: "Id", align: "center", minWidth: 170 },
  { id: "dateTime", label: "Date & Time", align: "center", minWidth: 170 },
  { id: "credit", label: "Credit", align: "center", minWidth: 170 },
  { id: "debit", label: "Debit", align: "center", minWidth: 170 },
  { id: "balance", label: "Balance", align: "center", minWidth: 170 },
  { id: "details", label: "Details", align: "center", minWidth: 170 },
];

const defaultAddDialog = {
  credit: null,
  debit: null,
  description: "",
  itemType: "",
  driver: "",
  vehicle: "",
  litre: null,
  show: false,
};

export default function Account() {
  const [account, setAccount] = useState(null);
  const [addDialog, setAddDialog] = useState(defaultAddDialog);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pagination, setPagination] = useState({
    nextToken: null,
    previousToken: null,
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
  });
  const [detailsDialog, setDetailsDialog] = useState({
    show: false,
    description: "",
    itemType: "",
    driver: "",
    vehicle: "",
    litre: null,
  });
  const dispatch = useDispatch();

  let { id } = useParams();

  const setPageTitle = (accountName) => {
    dispatch(
      setTitle(CreateLinkTitle("/Accounting", "Accounting", accountName))
    );
  };

  const getAccount = async () => {
    var result = await ApiGet(dispatch, "Accounting/" + id);
    if (result != null) {
      setAccount(result.data);
    }
  };

  const createRow = (entry) => {
    var localDate = convertLocalDateToUTCDate(entry.dateTime, false);
    var detailsButton = (
      <Button
        onClick={() => setDetailsDialog({ ...entry, show: true })}
        variant="contained"
      >
        Details
      </Button>
    );
    return {
      entryId: entry.entryId,
      dateTime: (
        <>
          {localDate.toLocaleDateString("en-IN")}
          <br /> {localDate.toLocaleTimeString("en-IN")}
        </>
      ),
      credit:
        entry.credit == null
          ? null
          : new Intl.NumberFormat("en-IN").format(entry.credit),
      debit:
        entry.debit == null
          ? null
          : new Intl.NumberFormat("en-IN").format(entry.debit),
      balance: new Intl.NumberFormat("en-IN").format(entry.balance),
      details: detailsButton,
    };
  };

  const getEntries = async (token, limit) => {
    var result = await ApiGet(
      dispatch,
      "Accounting/" +
        id +
        "/Entries?limit=" +
        limit +
        "&token=" +
        (token == null ? "" : token)
    );
    if (result != null) {
      setRows(result.data.items.map((x) => createRow(x)));
      setPagination({
        nextToken: result.data.nextToken,
        previousToken: result.data.previousToken,
        currentPage: result.data.currentPage,
        totalPages: result.data.totalPages,
        totalItems: result.data.totalItemCount,
      });
    }
  };

  useEffect(() => {
    getAccount();
    getEntries(null, rowsPerPage);
  }, []);

  useEffect(() => {
    if (account != null) {
      setPageTitle(account.name);
    }
  }, [account]);

  const handleChangeRowsPerPage = async (v) => {
    setRowsPerPage(v);
    await getEntries(null, v);
  };

  const handlePageChanged = async (v) => {
    if (v && pagination.nextToken != null) {
      await getEntries(pagination.nextToken, rowsPerPage);
    } else if (!v && pagination.previousToken != null) {
      await getEntries(pagination.previousToken, rowsPerPage);
    }
  };

  const entryDialogAddClicked = async () => {
    var result = await ApiPost(
      dispatch,
      "Accounting/" + account.id + "/Entries",
      addDialog
    );
    if (result) {
      setAddDialog(defaultAddDialog);
      getEntries(null, rowsPerPage);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        sx={{ margin: "5px" }}
        onClick={() =>
          setAddDialog({
            credit: null,
            debit: null,
            description: "",
            itemType: "",
            driver: "",
            vehicle: "",
            litre: null,
            show: true,
          })
        }
      >
        Add
      </Button>
      <AddEntryDialog
        show={addDialog.show}
        close={() => setAddDialog((prv) => ({ ...prv, show: false }))}
        onValueChange={(val) => setAddDialog((prv) => ({ ...prv, ...val }))}
        account={account}
        add={entryDialogAddClicked}
      />

      <DetailsDialog
        show={detailsDialog.show}
        data={detailsDialog}
        close={() => setDetailsDialog((prv) => ({ ...prv, show: false }))}
        accountType={account?.accountType ?? null}
      />

      <DataTable
        columns={columns}
        rows={rows}
        pagination={pagination}
        rowsPerPage={rowsPerPage}
        handleChangeRowsPerPage={(v) => handleChangeRowsPerPage(v)}
        handlePageChanged={(v) => handlePageChanged(v)}
      />
    </>
  );
}
