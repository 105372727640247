import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import DataTable from "../../../components/data/DataTable";
import DeleteDialog from "../../../components/data/DeleteDialog";
import { pushMessage } from "../../../features/messages";
import { Button, IconButton } from "@mui/material";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
} from "../../../services/ApiService";
import DeleteIcon from "@mui/icons-material/Delete";
import AddFuelTypes from "./AddFuelTypes";
import UpdateFuelType from "./UpdateFuelType";
import { setTitle } from "../../../features/title";
import { CreateLinkTitle } from "../../../utils/TitleUtils";

const columns = [
  { id: "name", label: "Fuel Type", align: "center", minWidth: 170 },
  { id: "color", label: "Color", align: "center", minWidth: 170 },
  { id: "update", label: "Update", align: "center", minWidth: 170 },
  {
    id: "delete",
    label: "Delete",
    minWidth: 170,
    align: "center",
  },
];

export default function FuelTypes() {
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pagination, setPagination] = useState({
    nextToken: null,
    previousToken: null,
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
  });
  const [deleteDialog, setDeleteDialog] = useState({
    show: false,
    name: "",
    id: "",
  });
  const [addDialog, setAddDialog] = useState({
    show: false,
    name: "",
    color: "#000",
  });
  const [updateDialog, setUpdateDialog] = useState({
    id: "",
    show: false,
    name: "",
    color: "#000",
  });

  const dispatch = useDispatch();
  dispatch(
    setTitle(CreateLinkTitle("/Shift-Manager", "Shift Manager", "Fuel Types"))
  );

  const createRow = (fuelType) => {
    var deleteButton = (
      <IconButton onClick={() => onDeleteButtonClicked(fuelType)}>
        <DeleteIcon color="error" />
      </IconButton>
    );
    var colorButton = (
      <Button
        sx={{ backgroundColor: fuelType.color, fontWeight: "bold" }}
        disabled
      >
        {fuelType.color}
      </Button>
    );
    var updateButton = (
      <Button
        variant="contained"
        color="primary"
        onClick={() => onUpdateButtonClicked(fuelType)}
      >
        Update
      </Button>
    );
    return {
      name: fuelType.name,
      color: colorButton,
      update: updateButton,
      delete: deleteButton,
    };
  };

  const onUpdateButtonClicked = (fuelType) => {
    setUpdateDialog({
      id: fuelType.id,
      name: fuelType.name,
      color: fuelType.color,
      show: true,
    });
  };

  const getData = async (token, limit) => {
    var result = await ApiGet(
      dispatch,
      "FuelTypes?limit=" + limit + "&token=" + (token == null ? "" : token)
    );
    if (result) {
      setRows(result.data.items.map((x) => createRow(x)));
      setPagination({
        nextToken: result.data.nextToken,
        previousToken: result.data.previousToken,
        currentPage: result.data.currentPage,
        totalPages: result.data.totalPages,
        totalItems: result.data.totalItemCount,
      });
    }
  };

  useEffect(() => {
    getData(null, rowsPerPage);
  }, []);

  const onDeleteButtonClicked = (fuelType) => {
    setDeleteDialog({
      show: true,
      name: fuelType.name,
      id: fuelType.id,
    });
  };

  const handlePageChanged = async (v) => {
    if (v && pagination.nextToken != null) {
      await getData(pagination.nextToken, rowsPerPage);
    } else if (!v && pagination.previousToken != null) {
      await getData(pagination.previousToken, rowsPerPage);
    }
  };

  const handleChangeRowsPerPage = async (v) => {
    setRowsPerPage(v);
    await getData(null, v);
  };

  const deleteConfirmed = async () => {
    var result = await ApiDelete(dispatch, "FuelTypes/" + deleteDialog.id);
    if (result) {
      setDeleteDialog((prv) => ({ ...prv, show: false }));
      getData(null, rowsPerPage);
    }
  };

  const addClicked = async () => {
    var result = await ApiPost(dispatch, "FuelTypes", {
      name: addDialog.name,
      color: addDialog.color,
    });
    if (result) {
      setAddDialog((prv) => ({ ...prv, show: false }));
      getData(null, rowsPerPage);
    }
  };

  const updateClicked = async () => {
    var result = await ApiPut(dispatch, "FuelTypes/" + updateDialog.id, {
      name: updateDialog.name,
      color: updateDialog.color,
    });
    if (result) {
      setUpdateDialog((prv) => ({ ...prv, show: false }));
      getData(null, rowsPerPage);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        sx={{ margin: "5px" }}
        onClick={() => setAddDialog({ show: true, name: "" })}
      >
        Add
      </Button>
      <AddFuelTypes
        show={addDialog.show}
        onNameValueChange={(value) =>
          setAddDialog((prv) => ({ ...prv, name: value }))
        }
        onColorValueChange={(value) =>
          setAddDialog((prv) => ({ ...prv, color: value }))
        }
        close={() => setAddDialog((prv) => ({ ...prv, show: false }))}
        add={addClicked}
      />
      <UpdateFuelType
        show={updateDialog.show}
        name={updateDialog.name}
        color={updateDialog.color}
        onNameValueChange={(value) =>
          setUpdateDialog((prv) => ({ ...prv, name: value }))
        }
        onColorValueChange={(value) =>
          setUpdateDialog((prv) => ({ ...prv, color: value }))
        }
        close={() => setUpdateDialog((prv) => ({ ...prv, show: false }))}
        update={updateClicked}
      />
      <DeleteDialog
        name={deleteDialog.name}
        show={deleteDialog.show}
        onDeleteClicked={deleteConfirmed}
        close={() => setDeleteDialog((prv) => ({ ...prv, show: false }))}
      />
      <DataTable
        columns={columns}
        rows={rows}
        pagination={pagination}
        rowsPerPage={rowsPerPage}
        handleChangeRowsPerPage={(v) => handleChangeRowsPerPage(v)}
        handlePageChanged={(v) => handlePageChanged(v)}
      />
    </>
  );
}
