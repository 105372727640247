import DashboardIcon from "@mui/icons-material/Dashboard";
import TimerIcon from "@mui/icons-material/Timer";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import BadgeIcon from "@mui/icons-material/Badge";
import OilBarrelIcon from "@mui/icons-material/OilBarrel";
import SettingsIcon from "@mui/icons-material/Settings";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import LabelIcon from "@mui/icons-material/Label";

const shiftManager = [
  {
    name: "Shift Manager",
    path: "/Shift-Manager",
    icon: <TimerIcon />,
  },
];

const dashboard = [
  {
    name: "Dashboard",
    path: "/",
    icon: <DashboardIcon />,
  },
];

const accounting = [
  {
    name: "Accounting",
    path: "/Accounting",
    icon: <LibraryBooksIcon />,
  },
];

const oil = [
  {
    name: "Oil",
    path: "/Oil",
    icon: <WaterDropIcon />,
  },
];

const settings = [
  {
    name: "Settings",
    path: "/Settings",
    icon: <SettingsIcon />,
  },
];

export const HomeMenuBehaviour = [
  dashboard,
  shiftManager,
  accounting,
  oil,
  settings,
];

export const ShiftManagerMenuBehaviour = [
  dashboard,
  [
    {
      name: "Shift Manager",
      path: "/Shift-Manager",
      icon: <TimerIcon />,
    },
    {
      name: "Machines",
      path: "/Shift-Manager/Machines",
      icon: <LocalGasStationIcon />,
    },
    {
      name: "Employees",
      path: "/Shift-Manager/Employees",
      icon: <BadgeIcon />,
    },
    {
      name: "Fuel Types",
      path: "/Shift-Manager/Fuel-Types",
      icon: <OilBarrelIcon />,
    },
    {
      name: "Fuel Prices",
      path: "/Shift-Manager/Fuel-Prices",
      icon: <CurrencyRupeeIcon />,
    },
    {
      name: "Labels",
      path: "/Shift-Manager/Labels",
      icon: <LabelIcon />,
    },
  ],
  accounting,
  oil,
  settings,
];

export const SettingsBehaviour = [
  dashboard,
  shiftManager,
  accounting,
  oil,
  [
    {
      name: "Settings",
      path: "/Settings",
      icon: <SettingsIcon />,
    },
    {
      name: "Users",
      path: "/Settings/Users",
      icon: <ManageAccountsIcon />,
    },
  ],
];
