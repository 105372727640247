import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import React from "react";

export default function DeleteDialog(props) {
  return (
    <>
      <Dialog open={props.show}>
        <DialogTitle sx={{ backgroundColor: "#B4ADA4" }}>
          Are you sure you want to delete "{props.name}"?
        </DialogTitle>
        <DialogActions sx={{ backgroundColor: "#B4ADA4" }}>
          <Button variant="contained" onClick={props.close}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={props.onDeleteClicked}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
