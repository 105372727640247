import React from "react";
import { Button, Dialog, DialogActions, DialogContent, TextField } from "@mui/material";
import { HexColorPicker } from "react-colorful";

export default function AddFuelTypes(props) {
  return (
    <Dialog open={props.show}>
      <DialogContent sx={{ backgroundColor: "#B4ADA4" }}>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="New Fuel Type"
          fullWidth
          variant="standard"
          onChange={(e) => props.onNameValueChange(e.target.value)}
        />
        <HexColorPicker onChange={(e)=> props.onColorValueChange(e)}/>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: "#B4ADA4" }}>
        <Button variant="contained" onClick={props.close}>
          Cancel
        </Button>
        <Button variant="contained" color="success" onClick={props.add}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
