import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";

export default function Nozzles(props) {
  return (
    <Dialog open={props.show}>
      <DialogTitle sx={{ backgroundColor: "#B4ADA4" }}>
        <Typography>{props.machine}</Typography>
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: "#B4ADA4" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow
              sx={{
                "& th": {
                  color: "black",
                  backgroundColor: "#B4ADA4",
                },
                "& .MuiTableCell-root": {
                  borderBottom: "1px solid #67635e",
                },
              }}
            >
              <TableCell
                key="name"
                align="center"
                style={{ minWidth: 170, fontSize: "120%" }}
              >
                Nozzle
              </TableCell>
              <TableCell
                key="fuelType"
                align="center"
                style={{ minWidth: 170, fontSize: "120%" }}
              >
                Fuel Type
              </TableCell>

              <TableCell
                key="delete"
                align="center"
                style={{ minWidth: 170, fontSize: "120%" }}
              >
                Delete
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows.map((row) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.code}
                  sx={{
                    backgroundColor: "#B4ADA4",
                    "&.MuiTableRow-hover:hover": {
                      backgroundColor: "#B4ADA4",
                    },
                    "& .MuiTableCell-root": {
                      borderBottom: "1px solid #67635e",
                    },
                  }}
                >
                  <TableCell key="name" align="center" sx={{ color: "black" }}>
                    {row.name}
                  </TableCell>

                  <TableCell
                    key="fuelType"
                    align="center"
                    sx={{ color: "black" }}
                  >
                    {row.fuelTypeName}
                  </TableCell>
                  <TableCell
                    key="delete"
                    align="center"
                    sx={{ color: "black" }}
                  >
                    {row.delete}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: "#B4ADA4" }}>
        <Button variant="contained" onClick={props.close}>
          Cancel
        </Button>
        <Button variant="contained" onClick={props.add}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
