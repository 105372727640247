export function ParseDate(date) {
  if (date == null) {
    return null;
  }
  return date.year() + "-" + (date.month() + 1) + "-" + date.date();
}

export function DayMinusOne(date) {
  if (date == null) {
    return null;
  }
  return date.year() + "-" + (date.month() + 1) + "-" + (date.date()-1);
}

export function convertLocalDateToUTCDate(date, toUTC) {
  date = new Date(date);
  var localOffset = date.getTimezoneOffset() * 60000;
  var localTime = date.getTime();
  if (toUTC) {
    date = localTime + localOffset;
  } else {
    date = localTime - localOffset;
  }
  date = new Date(date);
  return date;
}
