import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";

export default function AddAccountDialog(props) {
  return (
    <>
      <Dialog open={props.show}>
        <DialogContent sx={{ backgroundColor: "#B4ADA4" }}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Account"
            fullWidth
            variant="standard"
            onChange={(e) => props.onNameChange(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Phone Number"
            fullWidth
            variant="standard"
            onChange={(e) => props.onPhoneChange(e.target.value)}
          />
          <FormControl fullWidth sx={{marginTop:"10px"}}>
            <InputLabel id="demo-simple-select-label">Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Account Type"
              onChange={(e) => props.onAccountTypeChange(e.target.value)}
            >
              <MenuItem value="General">General</MenuItem>
              <MenuItem value="Employee">Employee</MenuItem>
              <MenuItem value="FuelCredit">FuelCredit</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: "#B4ADA4" }}>
          <Button variant="contained" onClick={props.close}>
            Cancel
          </Button>
          <Button variant="contained" color="success" onClick={props.add}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
