import {
  MenuItem,
  Grid,
  Select,
  Paper,
  Typography,
  Box,
  Button,
  TextField,
  IconButton,
} from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import React, { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { ApiGet, ApiPost, ApiPut } from "../../services/ApiService";
import { useDispatch } from "react-redux";
import ReadingRow from "./ReadingRow";
import "../../App.css";
import { dinominations } from "../../data/dinominations";
import FuelPricesDialog from "./fuelPrices/FuelPricesDialog";
import { ParseDate } from "../../utils/DateTimeUtils";
import { pushMessage } from "../../features/messages";
import { setTitle } from "../../features/title";
import { CreateSimpleTitle } from "../../utils/TitleUtils";
import AddLabelValueDialog from "./AddLabelValueDialog";
import ModifyLabelValueDialog from "./ModifyLabelValueDialog";
import ImportExportIcon from "@mui/icons-material/ImportExport";

export default function ShiftManager() {
  const today = dayjs(new Date());
  const shouldDisableDate = (date) => date > today;
  const [machines, setMachines] = useState([]);
  const [selectedMachine, setSelectedMachine] = useState(null);
  const [date, setDate] = useState(today);
  const [nozzles, setNozzles] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedShift, setSelectedShift] = useState({
    shiftId: null,
    employeeId: null,
    startedAt: null,
    endedAt: null,
    nozzleEntries: [],
  });
  const [shifts, setShifts] = useState([]);
  const [fuelTypes, setFuelTypes] = useState([]);
  const dispatch = useDispatch();
  const [error, setError] = useState({
    endedAt: null,
  });
  const [showDinominations, setShowDinominations] = useState(false);
  const [fuelPrices, setFuelPrices] = useState([]);
  const [fuelPricesDialog, setFuelPricesDialog] = useState({
    show: false,
  });
  const [readingErrors, setReadingErros] = useState([]);
  const [total, setTotal] = useState(null);
  const [collection, setCollection] = useState(null);
  const [timer, setTimer] = useState(null);
  const [labels, setLabels] = useState(null);
  const [labelDialog, setLabelDialog] = useState({
    name: null,
    type: null,
    value: null,
    show: false,
  });
  const [modifyLabelDialog, setModifyLabelDialog] = useState({
    name: null,
    type: null,
    value: null,
    show: false,
  });
  const [absoluteCollection, setAbsoluteCollection] = useState(0);
  const [showImport, setShowImport] = useState(true);

  const getMachines = async () => {
    var result = await ApiGet(dispatch, "Machines?limit=100");
    if (result) {
      setMachines(result.data.items);
      setSelectedMachine(result.data.items[0]);
    }
  };

  const getEmployees = async () => {
    var result = await ApiGet(dispatch, "Employees?limit=100");
    if (result) {
      setEmployees(result.data.items);
    }
  };

  const getFuelTypes = async () => {
    var result = await ApiGet(dispatch, "FuelTypes?limit=100");
    if (result) {
      setFuelTypes((prv) => {
        const val = result.data.items;
        getFuelPrices(val);
        return val;
      });
    }
  };

  const getNozzles = async () => {
    var result = await ApiGet(dispatch, "Machines/" + selectedMachine.id);
    if (result) {
      setNozzles(result.data.nozzles);
    }
  };

  const getLabels = async () => {
    var result = await ApiGet(dispatch, "ShiftManager");
    if (result) {
      setLabels(result.data);
    }
  };

  const setSetSelectedShift = (shift) => {
    setSelectedShift({
      ...shift,
      startedAt: shift.startedAt ? dayjs(shift.startedAt, "HH:mm:ss") : null,
      endedAt: shift.endedAt ? dayjs(shift.endedAt, "HH:mm:ss") : null,
    });
  };

  const getMachineEntry = async (shiftId) => {
    var result = await ApiGet(
      null,
      "Machines/" + selectedMachine.id + "/Entry/" + ParseDate(date)
    );
    if (result) {
      if (result.data.shifts != null && result.data.shifts.length != 0) {
        setShifts(result.data.shifts);
        if (shiftId != null) {
          var shift = result.data.shifts.filter((x) => x.shiftId == shiftId);
          if (shift.length == 0) {
            setSetSelectedShift(
              result.data.shifts[result.data.shifts.length - 1]
            );
          } else {
            setSetSelectedShift(shift[0]);
          }
        } else {
          setSetSelectedShift(
            result.data.shifts[result.data.shifts.length - 1]
          );
        }
      }
    } else {
      setShifts([]);
      setSelectedShift({
        shiftId: null,
        startedAt: null,
        endedAt: null,
        employeeId: null,
        employeeName: null,
        nozzleEntries: nozzles,
      });
    }
  };

  const machineChanged = (machineId) => {
    setSelectedMachine({
      id: machineId,
      name: machines.filter((x) => x.id == machineId)[0].name,
    });
  };
  dispatch(setTitle(CreateSimpleTitle("Shift Manager")));

  useEffect(() => {
    if (selectedMachine != null) {
      getNozzles();
    }
  }, [selectedMachine]);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedMachine != null) {
        await getMachineEntry();
      }
      await getFuelPrices(fuelTypes);
    };

    fetchData();
  }, [date, selectedMachine]);

  useEffect(() => {
    const fetchData = async () => {
      await getFuelTypes();
      await getEmployees();
      await getMachines();
      await getLabels();
    };
    fetchData();
  }, []);

  const getFuelPrices = async (fts) => {
    var result = await ApiGet(null, "FuelPrices/" + ParseDate(date));
    if (result) {
      for (let index = 0; index < fts.length; index++) {
        if (
          result.data.prices.filter((x) => x.fuelTypeId == fts[index].id)
            .length == 0
        ) {
        }
      }
      setFuelPrices(
        result.data.prices.map((n) => {
          var fuelType = fts.filter((x) => x.id == n.fuelTypeId)[0];
          return {
            id: n.fuelTypeId,
            name: fuelType?.name ?? n.fuelTypeName,
            price: n.price,
            color: fuelType?.color ?? "gray",
          };
        })
      );
    } else {
      setFuelPrices(fts);
      if (date == today) {
        setFuelPricesDialog({
          show: true,
        });
      }
    }
  };

  const addNewShift = async () => {
    var result = await ApiPost(
      dispatch,
      "Machines/" + selectedMachine.id + "/Entry",
      { machineName: selectedMachine.name, date: ParseDate(date) }
    );
    if (result) {
      setShifts(result.data.shifts);
      setSetSelectedShift({
        ...result.data.shifts[result.data.shifts.length - 1],
        nozzleEntries: nozzles,
      });
    }
  };

  const saveShift = async (data) => {
    if (checkErrors(data) || ParseDate(today) != ParseDate(date)) {
      return;
    }

    var result = await ApiPut(
      null,
      "Machines/" + selectedMachine.id + "/Entry",
      {
        machineName: selectedMachine.name,
        date: ParseDate(date),
        shift: {
          ...data,
          dinominations: data.dinominations?.map((n) => {
            return {
              ...n,
              count: parseFloat(n.count) || 0,
            };
          }),
          startedAt: data.startedAt
            ? data.startedAt.$H + ":" + data.startedAt.$m + ":00"
            : null,
          endedAt: data.endedAt
            ? data.endedAt.$H + ":" + data.endedAt.$m + ":00"
            : null,
        },
      }
    );

    if (result) {
      setShifts(result.data.shifts);
    }
  };

  const checkErrors = (selShift) => {
    var err = false;
    if (
      (selShift.startedAt != null &&
        selShift.endedAt != null &&
        selShift.startedAt >= selShift.endedAt) ||
      (selShift.startedAt == null && selShift.endedAt != null)
    ) {
      setError((prv) => ({
        ...prv,
        endedAt: "Ended At has to be less than Started At",
      }));
      err = true;
    } else {
      setError((prv) => ({ ...prv, endedAt: null }));
      err = false;
    }
    return err;
  };

  const setTotalAndCollection = () => {
    setCollection((prv) => {
      var result = 0;
      selectedShift.dinominations?.forEach((element) => {
        result += parseFloat(element.value) * (parseFloat(element.count) || 0);
      });
      return result;
    });

    setTotal((prv) => {
      var result = 0;
      selectedShift.nozzleEntries?.forEach((element) => {
        if (
          element.openingReading != null &&
          element.closingReading != null &&
          parseFloat(element.openingReading) <=
            parseFloat(element.closingReading)
        ) {
          var fuelTypeId =
            nozzles.filter((x) => x.name == element.name)[0]?.fuelTypeId ||
            null;
          if (fuelTypeId != null) {
            var price =
              fuelPrices.filter((x) => x.id == fuelTypeId)[0]?.price || 0;
            result +=
              (parseFloat(element.closingReading) -
                parseFloat(element.openingReading) -
                (parseFloat(element.test) || 0)) *
              price;
          }
        }
      });
      return result.toFixed(2);
    });
  };

  useEffect(() => {
    if (selectedShift.shiftId != null) {
      checkErrors(selectedShift);
      setTotalAndCollection();
    }
    setShowImport(
      (selectedShift?.nozzleEntries?.filter(
        (x) =>
          x.openingReading == null ||
          x.openingReading == "" ||
          isNaN(x.openingReading)
      ).length ?? 0) == (selectedShift?.nozzleEntries?.length ?? 0)
    );
  }, [selectedShift, fuelPrices]);

  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  const fuelPricesSave = async () => {
    for (let index = 0; index < fuelPrices.length; index++) {
      if (fuelPrices[0].price == null || fuelPrices[0].price == 0) {
        dispatch(
          pushMessage({
            text: "Please provide all fuel rates for the day.",
            type: "warning",
            show: true,
          })
        );
        return;
      }
    }

    var result = await ApiPut(dispatch, "FuelPrices/" + ParseDate(date), {
      prices: fuelPrices.map((n) => {
        return {
          fuelTypeId: n.id,
          fuelTypeName: n.name,
          price: n.price,
        };
      }),
    });

    if (result) {
      setFuelPricesDialog({ show: false });
    }
  };

  const nozzleValueChange = async (val) => {
    if (
      selectedShift.nozzleEntries != null &&
      val.openingReading ==
        selectedShift.nozzleEntries.filter((x) => x.name == val.name)[0]
          .openingReading &&
      val.closingReading ==
        selectedShift.nozzleEntries.filter((x) => x.name == val.name)[0]
          .closingReading &&
      val.test ==
        selectedShift.nozzleEntries.filter((x) => x.name == val.name)[0].test
    ) {
      return;
    }

    setSelectedShift((prv) => {
      var entries = [];
      var newEntry = {
        openingReading: parseFloat(val.openingReading) ?? null,
        closingReading: parseFloat(val.closingReading) ?? null,
        test: parseFloat(val.test) ?? null,
        name: val.name,
      };
      if (prv.nozzleEntries == null || prv.nozzleEntries.length == 0) {
        nozzles.forEach((element) => {
          if (element.name == val.name) {
            entries.push(newEntry);
          } else {
            entries.push(element);
          }
        });
      } else {
        prv.nozzleEntries?.forEach((element) => {
          if (element.name == val.name) {
            entries.push(newEntry);
          } else {
            entries.push(element);
          }
        });
      }
      const v = {
        ...prv,
        nozzleEntries: entries,
      };
      if (!checkReadingErrors(v)) {
        shiftEntrySaveInitiation(v);
      }
      return v;
    });
  };

  const checkReadingErrors = (shift) => {
    var err = false;
    var closingErrors = [];
    for (let index = 0; index < shift.nozzleEntries?.length ?? 0; index++) {
      var entry = shift.nozzleEntries[index];
      if (
        entry.openingReading != null &&
        entry.closingReading != null &&
        parseFloat(entry.closingReading) < parseFloat(entry.openingReading)
      ) {
        closingErrors.push({
          name: entry.name,
          error: "Closing reading has to be >= Opening reading.",
        });
        err = true;
      }
    }
    setReadingErros(closingErrors);
    return err;
  };

  const onDinominationChange = (n, e) => {
    setSelectedShift((prv) => {
      var din = [];
      var newDin = {
        type: n.type,
        value: n.value,
        count: e.target.value,
      };
      if (prv.dinominations == null || prv.dinominations.length == 0) {
        din.push(newDin);
      } else if (
        prv.dinominations.filter((x) => x.type == n.type && x.value == n.value)
          .length == 0
      ) {
        din = [...prv.dinominations, newDin];
      } else {
        prv.dinominations?.forEach((element) => {
          if (n.value == element.value && n.type == element.type) {
            din.push(newDin);
          } else {
            din.push(element);
          }
        });
      }
      const val = {
        ...prv,
        dinominations: din,
      };

      shiftEntrySaveInitiation(val);
      return val;
    });
  };

  const shiftEntrySaveInitiation = (data) => {
    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(() => {
      saveShift(data);
    }, 3000);

    setTimer(newTimer);
  };

  const onLabelValueAddClicked = () => {
    var message = "";
    if (labelDialog.name == null) {
      message += "Please choose a label type. ";
    }

    if (labelDialog.value == null || labelDialog.value == "") {
      message += "Value is required.";
    }

    if (
      (labelDialog.type == "Additions" &&
        (selectedShift?.additions?.filter((x) => x.name == labelDialog.name)
          .length ?? 0) != 0) ||
      (labelDialog.type == "Subtractions" &&
        (selectedShift?.subtractions?.filter((x) => x.name == labelDialog.name)
          .length ?? 0) != 0)
    ) {
      message += "Label already present.";
    }

    if (message != "") {
      dispatch(
        pushMessage({
          text: message,
          type: "warning",
          show: true,
        })
      );
      return;
    }

    setSelectedShift((prv) => {
      var val = null;
      if (labelDialog.type == "Additions") {
        val = {
          ...prv,
          additions: [
            ...(prv.additions ?? []),
            { name: labelDialog.name, value: labelDialog.value },
          ],
        };
      }

      if (labelDialog.type == "Subtractions") {
        val = {
          ...prv,
          subtractions: [
            ...(prv.subtractions ?? []),
            { name: labelDialog.name, value: labelDialog.value },
          ],
        };
      }

      saveShift(val);
      return val;
    });
    setLabelDialog((prv) => ({ ...prv, show: false }));
  };

  const onLabelValueSaveClicked = () => {
    var message = "";
    if (modifyLabelDialog.name == null) {
      message += "Please choose a label type. ";
    }

    if (modifyLabelDialog.value == null || modifyLabelDialog.value == "") {
      message += "Value is required.";
    }

    if (message != "") {
      dispatch(
        pushMessage({
          text: message,
          type: "warning",
          show: true,
        })
      );
      return;
    }

    setSelectedShift((prv) => {
      var val = null;
      if (modifyLabelDialog.type == "Additions") {
        var add = [];
        prv.additions?.forEach((element) => {
          if (modifyLabelDialog.name == element.name) {
            add.push({
              name: modifyLabelDialog.name,
              value: modifyLabelDialog.value,
            });
          } else {
            add.push(element);
          }
        });
        if (prv.additions == null || prv.additions.length == 0) {
          add.push({
            name: modifyLabelDialog.name,
            value: modifyLabelDialog.value,
          });
        }

        val = {
          ...prv,
          additions: add,
        };
      }

      if (modifyLabelDialog.type == "Subtractions") {
        var sub = [];
        prv.subtractions?.forEach((element) => {
          if (modifyLabelDialog.name == element.name) {
            sub.push({
              name: modifyLabelDialog.name,
              value: modifyLabelDialog.value,
            });
          } else {
            sub.push(element);
          }
        });
        if (prv.subtractions == null || prv.subtractions.length == 0) {
          sub.push({
            name: modifyLabelDialog.name,
            value: modifyLabelDialog.value,
          });
        }

        val = {
          ...prv,
          subtractions: sub,
        };
      }

      saveShift(val);
      return val;
    });
    setModifyLabelDialog((prv) => ({ ...prv, show: false }));
  };

  const onLabelValueDeleteClicked = () => {
    setSelectedShift((prv) => {
      var val = null;
      if (modifyLabelDialog.type == "Additions") {
        var add = [];
        prv.additions?.forEach((element) => {
          if (element.name != modifyLabelDialog.name) {
            add.push(element);
          }
        });
        val = {
          ...prv,
          additions: add,
        };
      }

      if (modifyLabelDialog.type == "Subtractions") {
        var sub = [];
        prv.subtractions?.forEach((element) => {
          if (element.name != modifyLabelDialog.name) {
            sub.push(element);
          }
        });
        val = {
          ...prv,
          subtractions: sub,
        };
      }

      saveShift(val);
      return val;
    });
    setModifyLabelDialog((prv) => ({ ...prv, show: false }));
  };

  useEffect(() => {
    var add = parseFloat(
      selectedShift?.additions?.reduce((acc, obj) => {
        return acc + parseFloat(obj.value);
      }, 0) ?? 0
    );
    var sub = parseFloat(
      selectedShift?.subtractions?.reduce((acc, obj) => {
        return acc + parseFloat(obj.value);
      }, 0) ?? 0
    );
    setAbsoluteCollection((collection ?? 0) + add - sub);
  }, [collection, selectedShift?.additions, selectedShift?.subtractions]);

  const importOr = async () => {
    var result = await ApiPut(
      dispatch,
      "Machines/" +
        selectedMachine.id +
        "/Entry/" +
        ParseDate(date) +
        "/ImportOR/" +
        selectedShift.shiftId,
      null
    );
    if (result) {
      await getMachineEntry(selectedShift.shiftId);
    }
  };

  return (
    <>
      <FuelPricesDialog
        save={fuelPricesSave}
        onChange={(val) =>
          setFuelPrices((prv) => {
            var data = [];
            prv.forEach((element) => {
              if (element.id == val.id) {
                data.push(val);
              } else {
                data.push(element);
              }
            });
            return data;
          })
        }
        show={fuelPricesDialog.show}
        date={date}
        fuelPrices={fuelPrices}
      />
      <AddLabelValueDialog
        show={labelDialog.show}
        close={() => setLabelDialog((prv) => ({ ...prv, show: false }))}
        type={labelDialog.type}
        onChange={(val) => setLabelDialog((prv) => ({ ...prv, ...val }))}
        add={onLabelValueAddClicked}
        labels={
          labelDialog.type == "Additions"
            ? labels?.additions?.filter(
                (x) =>
                  (selectedShift?.additions?.filter((a) => a.name == x)
                    .length ?? 0) == 0
              )
            : labels?.subtractions?.filter(
                (x) =>
                  (selectedShift?.subtractions?.filter((a) => a.name == x)
                    .length ?? 0) == 0
              )
        }
      />
      <ModifyLabelValueDialog
        show={modifyLabelDialog.show}
        close={() => setModifyLabelDialog((prv) => ({ ...prv, show: false }))}
        type={modifyLabelDialog.type}
        onChange={(val) => setModifyLabelDialog((prv) => ({ ...prv, ...val }))}
        save={onLabelValueSaveClicked}
        delete={onLabelValueDeleteClicked}
        data={modifyLabelDialog}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Paper sx={{ backgroundColor: "#B4ADA4" }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <StaticDatePicker
                defaultValue={date}
                shouldDisableDate={shouldDisableDate}
                sx={{
                  width: "300px",
                  backgroundColor: "#B4ADA4",
                  margin: "2px",
                }}
                slotProps={{ actionBar: { actions: [] } }}
                onChange={(e) => setDate(e)}
              />
            </Grid>
            <Grid item xs={4}>
              <Box
                sx={{ margin: "20px" }}
                display="flex"
                flexDirection="column"
              >
                {fuelPrices.map((n) => {
                  return (
                    <>
                      <Paper
                        sx={{
                          backgroundColor: n.color,
                          marginBottom: "10px",
                          padding: "10px",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "row",
                          width: "300px",
                        }}
                      >
                        <Typography
                          fontWeight={"bold"}
                          fontSize={20}
                          color={"white"}
                        >
                          {n.name}&nbsp;-&nbsp;
                        </Typography>
                        <Typography
                          fontWeight={"bold"}
                          fontSize={20}
                          color={"white"}
                        >
                          {n.price}
                        </Typography>
                      </Paper>
                    </>
                  );
                })}
              </Box>
              <Box
                sx={{ margin: "20px" }}
                display="flex"
                flexDirection="column"
              >
                <Typography variant="h5">Machine</Typography>
                <Select
                  sx={{ width: "300px" }}
                  value={selectedMachine != null ? selectedMachine.id : null}
                  onChange={(e) => machineChanged(e.target.value)}
                >
                  {machines.map((n) => {
                    return <MenuItem value={n.id}>{n.name}</MenuItem>;
                  })}
                </Select>
              </Box>
              <Box
                sx={{ margin: "20px" }}
                display="flex"
                flexDirection="column"
              >
                <Typography variant="h5">Shift</Typography>
                <Select
                  sx={{ width: "300px" }}
                  value={selectedShift.shiftId}
                  onChange={(e) => {
                    setSelectedShift({
                      shiftId: e.target.value,
                      employeeId: null,
                      nozzleEntries: [],
                    });
                    getMachineEntry(e.target.value);
                  }}
                >
                  {shifts.map((n) => {
                    return (
                      <MenuItem value={n.shiftId}>
                        {n.shiftId} - {n.employeeName ?? "No Employee Assigned"}
                      </MenuItem>
                    );
                  })}
                </Select>
                <Button
                  sx={{ marginTop: "10px", width: "300px" }}
                  variant="contained"
                  onClick={addNewShift}
                  disabled={ParseDate(date) != ParseDate(today)}
                >
                  Add New Shift
                </Button>
              </Box>
            </Grid>
            {selectedShift != null && selectedShift.shiftId != null && (
              <Grid item xs={4}>
                <Box
                  sx={{ margin: "20px" }}
                  display="flex"
                  flexDirection="column"
                >
                  <Typography variant="h5">Employee</Typography>
                  <Select
                    sx={{ width: "300px" }}
                    value={selectedShift.employeeId}
                    onChange={(e) =>
                      setSelectedShift((prv) => {
                        const val = {
                          ...prv,
                          employeeId: e.target.value,
                          employeeName: employees.filter(
                            (x) => x.id == e.target.value
                          )[0]?.name,
                        };
                        saveShift(val);
                        return val;
                      })
                    }
                    disabled={ParseDate(date) != ParseDate(today)}
                  >
                    {employees.map((n) => {
                      return <MenuItem value={n.id}>{n.name}</MenuItem>;
                    })}
                  </Select>
                </Box>

                <Box
                  sx={{ margin: "20px" }}
                  display="flex"
                  flexDirection="column"
                >
                  <Typography variant="h5">Started At</Typography>
                  <Box>
                    <TimePicker
                      sx={{ width: "200px" }}
                      value={selectedShift.startedAt}
                      onChange={(e) =>
                        setSelectedShift((prv) => {
                          const val = {
                            ...prv,
                            startedAt: e,
                          };
                          saveShift(val);
                          return val;
                        })
                      }
                      disabled={ParseDate(date) != ParseDate(today)}
                    />
                    <Button
                      variant="contained"
                      sx={{ margin: "10px" }}
                      onClick={() => {
                        setSelectedShift((prv) => {
                          const val = {
                            ...prv,
                            startedAt: dayjs(new Date()),
                          };
                          saveShift(val);
                          return val;
                        });
                      }}
                      disabled={ParseDate(date) != ParseDate(today)}
                    >
                      Now
                    </Button>
                  </Box>
                </Box>
                <Box
                  sx={{ margin: "20px" }}
                  display="flex"
                  flexDirection="column"
                >
                  <Typography variant="h5">Ended At</Typography>
                  <Box>
                    <TimePicker
                      sx={{ width: "200px" }}
                      value={selectedShift.endedAt}
                      onChange={(e) =>
                        setSelectedShift((prv) => {
                          const val = {
                            ...prv,
                            endedAt: e,
                          };
                          saveShift(val);
                          return val;
                        })
                      }
                      slotProps={{
                        textField: {
                          variant: "outlined",
                          error: !!error.endedAt,
                          helperText: error.endedAt,
                          className: !!error.endedAt
                            ? "blinking-helper-text"
                            : "",
                        },
                      }}
                      disabled={ParseDate(date) != ParseDate(today)}
                    />
                    <Button
                      variant="contained"
                      sx={{ margin: "10px" }}
                      onClick={() => {
                        setSelectedShift((prv) => {
                          const val = {
                            ...prv,
                            endedAt: dayjs(new Date()),
                          };
                          saveShift(val);
                          return val;
                        });
                      }}
                      disabled={ParseDate(date) != ParseDate(today)}
                    >
                      Now
                    </Button>
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        </Paper>

        {selectedShift != null && selectedShift.shiftId != null && (
          <>
            <Paper sx={{ backgroundColor: "#B4ADA4", marginTop: "20px" }}>
              <Grid container sx={{ padding: "10px" }}>
                <Grid item xs={2}>
                  <Typography fontSize={20} fontWeight={"bold"} align="center">
                    Nozzle
                  </Typography>
                </Grid>
                <Grid item xs={4} display={"flex"} justifyContent={"center"}>
                  <Typography fontSize={20} fontWeight={"bold"} align="center">
                    Opening Reading
                  </Typography>
                  {showImport && (
                    <IconButton onClick={importOr}>
                      <ImportExportIcon />
                    </IconButton>
                  )}
                </Grid>
                <Grid item xs={2}>
                  <Typography fontSize={20} fontWeight={"bold"} align="center">
                    Test
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography fontSize={20} fontWeight={"bold"} align="center">
                    Closing Reading
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: "20px" }}>
                  {nozzles.map((n) => {
                    return (
                      <ReadingRow
                        disabled={ParseDate(date) != ParseDate(today)}
                        openingReading={
                          selectedShift.nozzleEntries?.filter(
                            (x) => x.name == n.name
                          )[0]?.openingReading || ""
                        }
                        closingReading={
                          selectedShift.nozzleEntries?.filter(
                            (x) => x.name == n.name
                          )[0]?.closingReading || ""
                        }
                        test={
                          selectedShift.nozzleEntries?.filter(
                            (x) => x.name == n.name
                          )[0]?.test || ""
                        }
                        nozzleName={n.name}
                        nozzleColor={
                          fuelTypes?.filter((x) => x.name == n.fuelTypeName)[0]
                            ?.color ?? "gray"
                        }
                        onChange={(val) => nozzleValueChange(val)}
                        error={
                          readingErrors.filter((x) => x.name == n.name)[0]
                            ?.error ?? null
                        }
                      />
                    );
                  })}
                </Grid>
              </Grid>
            </Paper>

            {/*Additions*/}
            <Paper
              sx={{
                backgroundColor: "#5d7d60",
                marginTop: "20px",
                padding: "20px",
              }}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={1}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Button
                    variant="contained"
                    onClick={() =>
                      setLabelDialog({
                        name: null,
                        type: "Additions",
                        value: null,
                        show: true,
                      })
                    }
                    disabled={ParseDate(date) != ParseDate(today)}
                  >
                    Add
                  </Button>
                </Grid>
                {selectedShift?.additions?.map((n) => {
                  return (
                    <>
                      <Grid item sx={{ marginRight: "30px" }}>
                        <Button
                          disabled={ParseDate(date) != ParseDate(today)}
                          variant="outlined"
                          sx={{
                            color: "white",
                            backgroundColor: "transparent",
                            borderColor: "#374938",
                            textTransform: "none",
                            fontSize: "20px",
                            ":hover": { borderColor: "#79a37d" },
                          }}
                          onClick={() =>
                            setModifyLabelDialog({
                              show: true,
                              name: n.name,
                              value: n.value,
                              type: "Additions",
                            })
                          }
                        >
                          <Typography fontWeight={"bold"} fontSize={"20px"}>
                            {n.name}
                          </Typography>
                          <Paper
                            sx={{
                              backgroundColor: "#79a37d",
                              marginLeft: "10px",
                              paddingLeft: "10px",
                              paddingRight: "10px",
                            }}
                          >
                            {new Intl.NumberFormat("en-IN").format(n.value)}
                          </Paper>
                        </Button>
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            </Paper>

            {/*Subtractions*/}
            <Paper
              sx={{
                backgroundColor: "#9c433e",
                marginTop: "20px",
                padding: "20px",
              }}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={1}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Button
                    variant="contained"
                    onClick={() =>
                      setLabelDialog({
                        name: null,
                        type: "Subtractions",
                        value: null,
                        show: true,
                      })
                    }
                    disabled={ParseDate(date) != ParseDate(today)}
                  >
                    Add
                  </Button>
                </Grid>
                {selectedShift?.subtractions?.map((n) => {
                  return (
                    <>
                      <Grid item sx={{ marginRight: "30px" }}>
                        <Button
                          disabled={ParseDate(date) != ParseDate(today)}
                          variant="outlined"
                          sx={{
                            color: "white",
                            backgroundColor: "transparent",
                            borderColor: "#76332f",
                            textTransform: "none",
                            fontSize: "20px",
                            ":hover": { borderColor: "#c2534d" },
                          }}
                          onClick={() =>
                            setModifyLabelDialog({
                              show: true,
                              name: n.name,
                              value: n.value,
                              type: "Subtractions",
                            })
                          }
                        >
                          <Typography fontWeight={"bold"} fontSize={"20px"}>
                            {n.name}
                          </Typography>
                          <Paper
                            sx={{
                              backgroundColor: "#c2534d",
                              marginLeft: "10px",
                              paddingLeft: "10px",
                              paddingRight: "10px",
                            }}
                          >
                            {new Intl.NumberFormat("en-IN").format(n.value)}
                          </Paper>
                        </Button>
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            </Paper>

            <Paper sx={{ backgroundColor: "#aea69c", marginTop: "20px" }}>
              <Paper
                sx={{
                  backgroundColor: "#B4ADA4",
                  marginTop: "20px",
                  padding: "20px",
                }}
              >
                {/*total collection difference*/}
                <Grid container>
                  <Grid
                    item
                    xs={3}
                    display={"flex"}
                    justifyContent={"space-evenly"}
                  >
                    <Box display={"flex"} flexDirection="column">
                      <Typography fontSize={20} color={"#4f4f4d"}>
                        Total
                      </Typography>
                      <Typography fontSize={25}>
                        {new Intl.NumberFormat("en-IN").format(total)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    display={"flex"}
                    justifyContent={"space-evenly"}
                  >
                    <Box display={"flex"} flexDirection="column">
                      <Typography fontSize={20} color={"#4f4f4d"}>
                        Collection
                      </Typography>
                      <Typography
                        display={"flex"}
                        flexDirection="row"
                        fontSize={25}
                      >
                        (
                        <Typography fontSize={25}>
                          {new Intl.NumberFormat("en-IN").format(collection)}
                        </Typography>
                        <Typography fontSize={25} color={"#4a691b"}>
                          &nbsp;+
                          {new Intl.NumberFormat("en-IN").format(
                            selectedShift?.additions?.reduce((acc, obj) => {
                              return acc + parseFloat(obj.value);
                            }, 0) ?? 0
                          )}
                        </Typography>
                        <Typography fontSize={25} color={"#76332f"}>
                          &nbsp;-
                          {new Intl.NumberFormat("en-IN").format(
                            selectedShift?.subtractions?.reduce((acc, obj) => {
                              return acc + parseFloat(obj.value);
                            }, 0) ?? 0
                          )}
                        </Typography>
                        )&nbsp;=&nbsp;
                        <Typography fontSize={25}>
                          {new Intl.NumberFormat("en-IN").format(
                            absoluteCollection
                          )}
                        </Typography>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    display={"flex"}
                    justifyContent={"space-evenly"}
                  >
                    <Box display={"flex"} flexDirection="column">
                      <Typography fontSize={20} color={"#4f4f4d"}>
                        Difference
                      </Typography>
                      <Typography
                        fontSize={25}
                        color={
                          (collection || 0) +
                            (selectedShift?.additions?.reduce((acc, obj) => {
                              return acc + parseFloat(obj.value);
                            }, 0) ?? 0) -
                            (selectedShift?.subtractions?.reduce((acc, obj) => {
                              return acc + parseFloat(obj.value);
                            }, 0) ?? 0) -
                            (total || 0) ==
                          0
                            ? "black"
                            : absoluteCollection - (total || 0) > 0
                            ? "#4a691b"
                            : "#76332f"
                        }
                      >
                        {new Intl.NumberFormat("en-IN").format(
                          absoluteCollection - (total || 0)
                        )}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    display={"flex"}
                    justifyContent={"end"}
                    alignItems={"center"}
                  >
                    <Button
                      variant="contained"
                      onClick={() => setShowDinominations(!showDinominations)}
                      sx={{ height: "40px" }}
                    >
                      {showDinominations ? "Hide" : "Show"}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
              {showDinominations && (
                <Grid
                  sx={{ margin: "10px", padding: "10px" }}
                  display={"flex"}
                  justifyContent={"space-evenly"}
                >
                  {dinominations.map((d) => {
                    const items = [];
                    var oldItems = [...d.items];
                    while (oldItems.length > 0) {
                      items.push(oldItems.splice(0, 5));
                    }
                    return (
                      <>
                        {items.map((i) => {
                          return (
                            <>
                              <Grid item xs={3}>
                                {i.map((n) => {
                                  return (
                                    <Box
                                      border={1}
                                      borderRadius={2}
                                      p={2}
                                      display="flex"
                                      flexDirection="row"
                                      justifyContent={"space-between"}
                                      alignItems={"center"}
                                      width={"300px"}
                                      height={"57px"}
                                      marginBottom={"5px"}
                                    >
                                      {n.indicator}
                                      <TextField
                                        disabled={
                                          ParseDate(date) != ParseDate(today)
                                        }
                                        value={
                                          selectedShift.dinominations?.filter(
                                            (x) =>
                                              x.value == n.value &&
                                              x.type == n.type
                                          )[0]?.count || ""
                                        }
                                        onChange={(e) => {
                                          e.target.value < 0
                                            ? (e.target.value = 0)
                                            : e.target.value.includes(".")
                                            ? (e.target.value = e.target.value
                                                .split(".")
                                                .pop())
                                            : onDinominationChange(n, e);
                                        }}
                                        type="number"
                                        sx={{
                                          width: "200px",
                                          input: {
                                            textAlign: "center",
                                            fontSize: 17,
                                            fontWeight: "bold",
                                          },
                                          border: "none",
                                          "& fieldset": { border: "none" },
                                        }}
                                      />
                                    </Box>
                                  );
                                })}
                              </Grid>
                            </>
                          );
                        })}
                      </>
                    );
                  })}
                </Grid>
              )}
            </Paper>

            {/*notes*/}
            <Paper sx={{ backgroundColor: "#aea69c", marginTop: "20px" }}>
              <TextField
                fullWidth
                multiline
                rows={3}
                value={selectedShift?.note}
                onChange={(e) =>
                  setSelectedShift((prv) => {
                    const val = {
                      ...prv,
                      note: e.target.value,
                    };
                    shiftEntrySaveInitiation(val);
                    return val;
                  })
                }
              />
            </Paper>
          </>
        )}
      </LocalizationProvider>
    </>
  );
}
