import {
  Typography,
  Dialog,
  DialogContent,
  Grid,
  DialogActions,
  Button,
  Paper,
  DialogTitle,
  TextField,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ParseDate, DayMinusOne } from "../../../utils/DateTimeUtils";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { useDispatch } from "react-redux";
import { ApiGet } from "../../../services/ApiService";

export default function FuelPricesDialog(props) {
  const [prices, setPrices] = useState([]);
  const dispatch = useDispatch();

  const getPreviousPrices = async () => {
    var result = await ApiGet(
      dispatch,
      "FuelPrices/" + DayMinusOne(props.date)
    );
    if (result) {
      setPrices((prv) => {
        var newList = [];
        prv.forEach((element) => {
          var old = result.data.prices.filter(
            (x) => x.fuelTypeName == element.name
          );
          if (old.length != 0) {
            element.price = old[0].price;
          }
          newList.push(element);
        });
        return newList;
      });
    }
  };

  useEffect(() => {
    setPrices(props.fuelPrices);
  }, [props.fuelPrices]);

  return (
    <>
      <Dialog open={props.show}>
        <DialogTitle sx={{ backgroundColor: "#B4ADA4" }}>
          {ParseDate(props.date)}
          <IconButton onClick={getPreviousPrices}>
            <ImportExportIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: "#B4ADA4" }}>
          <Grid container spacing={2} marginTop={"10px"} width={"400px"}>
            {prices.map((n) => {
              return (
                <>
                  <Grid item xs={12}>
                    <Paper
                      align="center"
                      sx={{
                        backgroundColor: n.color,
                        padding: "10px",
                        height: "70px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        color={"white"}
                        fontWeight={"bold"}
                        fontSize={20}
                      >
                        {n.name}
                      </Typography>
                      <TextField
                        value={n.price}
                        onChange={(e) => {
                          props.onChange({ ...n, price: e.target.value });
                          setPrices((prv) => {
                            var newList = [];
                            prv.forEach((element) => {
                              if (element.name == n.name) {
                                newList.push({
                                  ...element,
                                  price: e.target.value,
                                });
                              } else {
                                newList.push(element);
                              }
                            });
                            return newList;
                          });
                        }}
                        type="number"
                      />
                    </Paper>
                  </Grid>
                </>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: "#B4ADA4" }}>
          <Button variant="contained" color="success" onClick={props.save}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
