import React, { useEffect, useState } from "react";
import AddAccountDialog from "./AddAccountDialog";
import DataTable from "../../components/data/DataTable";
import { useDispatch } from "react-redux";
import { Button } from "@mui/material";
import { ApiGet, ApiPost } from "../../services/ApiService";
import { CreateSimpleTitle } from "../../utils/TitleUtils";
import { setTitle } from "../../features/title";

const columns = [
  { id: "name", label: "Account", align: "center", minWidth: 170 },
  { id: "accountType", label: "Type", align: "center", minWidth: 170 },
  { id: "phone", label: "Phone", align: "center", minWidth: 100 },
  { id: "balance", label: "Balance", align: "center", minWidth: 100 },
  {
    id: "view",
    label: "View",
    minWidth: 170,
    align: "center",
  },
];

export default function Accounting() {
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pagination, setPagination] = useState({
    nextToken: null,
    previousToken: null,
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
  });
  const [addDialog, setAddDialog] = useState({
    show: false,
    name: "",
    accountType: "",
    phoneNumber: "",
  });

  const dispatch = useDispatch();
  dispatch(setTitle(CreateSimpleTitle("Accounting")));

  const createRow = (account) => {
    var viewButton = (
      <Button variant="contained" href={"/Accounting/" + account.id}>
        View
      </Button>
    );
    return {
      name: account.name,
      accountType: account.accountType,
      phone: account.phoneNumber,
      balance: new Intl.NumberFormat("en-IN").format(account.balance),
      view: viewButton,
    };
  };

  const getData = async (token, limit) => {
    var result = await ApiGet(
      dispatch,
      "Accounting?limit=" + limit + "&token=" + (token == null ? "" : token)
    );
    if (result != null) {
      setRows(result.data.items.map((x) => createRow(x)));
      setPagination({
        nextToken: result.data.nextToken,
        previousToken: result.data.previousToken,
        currentPage: result.data.currentPage,
        totalPages: result.data.totalPages,
        totalItems: result.data.totalItemCount,
      });
    }
  };

  useEffect(() => {
    getData(null, rowsPerPage);
  }, []);

  const handleChangeRowsPerPage = async (v) => {
    setRowsPerPage(v);
    await getData(null, v);
  };

  const handlePageChanged = async (v) => {
    if (v && pagination.nextToken != null) {
      await getData(pagination.nextToken, rowsPerPage);
    } else if (!v && pagination.previousToken != null) {
      await getData(pagination.previousToken, rowsPerPage);
    }
  };

  const addClicked = async () => {
    var result = await ApiPost(dispatch, "Accounting", {
      name: addDialog.name,
      accountType: addDialog.accountType,
      phoneNumber: addDialog.phoneNumber,
    });
    if (result) {
      setAddDialog((prv) => ({ ...prv, show: false }));
      getData(null, rowsPerPage);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        sx={{ margin: "5px" }}
        onClick={() =>
          setAddDialog({
            show: true,
            name: "",
            accountType: "",
            phoneNumber: "",
          })
        }
      >
        Add
      </Button>
      <AddAccountDialog
        show={addDialog.show}
        close={() => setAddDialog((prv) => ({ ...prv, show: false }))}
        onNameChange={(val) => setAddDialog((prv) => ({ ...prv, name: val }))}
        onPhoneChange={(val) =>
          setAddDialog((prv) => ({ ...prv, phoneNumber: val }))
        }
        onAccountTypeChange={(val) =>
          setAddDialog((prv) => ({ ...prv, accountType: val }))
        }
        add={addClicked}
      />
      <DataTable
        columns={columns}
        rows={rows}
        pagination={pagination}
        rowsPerPage={rowsPerPage}
        handleChangeRowsPerPage={(v) => handleChangeRowsPerPage(v)}
        handlePageChanged={(v) => handlePageChanged(v)}
      />
    </>
  );
}
