import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

export default function AddNozzle(props) {
  return (
    <Dialog open={props.show}>
      <DialogTitle sx={{ backgroundColor: "#B4ADA4" }}>
        <Typography>{props.machine}</Typography>
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: "#B4ADA4" }}>
        <TextField
          autoFocus
          margin="dense"
          sx={{marginBottom:"30px"}}
          id="name"
          label="New Nozzle"
          fullWidth
          variant="standard"
          onChange={(e) => props.onNameChange(e.target.value)}
        />
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Fuel Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Fuel Type"
            onChange={(e) => props.onFuelTypeChange(e.target.value)}
          >
            {props.fuelTypes.map((n) => {
              return <MenuItem value={n.id}>{n.name}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: "#B4ADA4" }}>
        <Button variant="contained" onClick={props.close}>
          Cancel
        </Button>
        <Button variant="contained" color="success" onClick={props.add}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
