import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut
} from "../../../services/ApiService";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, IconButton } from "@mui/material";
import DataTable from "../../../components/data/DataTable";
import AddEmployee from "./AddEmployee";
import DeleteDialog from "../../../components/data/DeleteDialog";
import EditIcon from "@mui/icons-material/Edit";
import EditEmployee from "./EditEmployee";
import { setTitle } from "../../../features/title";
import { CreateLinkTitle } from "../../../utils/TitleUtils";

const columns = [
  { id: "name", label: "Name", align: "center", minWidth: 170 },
  { id: "phone", label: "Phone", align: "center", minWidth: 100 },
  { id: "address", label: "Address", align: "center", minWidth: 100 },
  { id: "edit", label: "Edit", align: "center", minWidth: 100 },
  {
    id: "delete",
    label: "Delete",
    minWidth: 170,
    align: "center",
  },
];

export default function Employees() {
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pagination, setPagination] = useState({
    nextToken: null,
    previousToken: null,
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
  });
  const [addDialog, setAddDialog] = useState({
    show: false,
    name: "",
    phone: null,
    address: null,
  });
  const [deleteDialog, setDeleteDialog] = useState({
    show: false,
    name: "",
    id: "",
  });
  const [editDialog, setEditDialog] = useState({
    show: false,
    id: "",
    name: "",
    phone: "",
    address: "",
  });

  const dispatch = useDispatch();

  dispatch(
    setTitle(CreateLinkTitle("/Shift-Manager", "Shift Manager", "Employees"))
  );

  const createRow = (employee) => {
    var deleteButton = (
      <IconButton onClick={() => onDeleteButtonClicked(employee)}>
        <DeleteIcon color="error" />
      </IconButton>
    );
    var editButton = (
      <IconButton onClick={() => onEditButtonClicked(employee)}>
        <EditIcon color="primary" />
      </IconButton>
    );

    return {
      name: employee.name,
      phone: employee.phone,
      address: employee.address,
      edit: editButton,
      delete: deleteButton,
    };
  };

  const onEditButtonClicked = (employee) => {
    setEditDialog({
      show: true,
      id: employee.id,
      name: employee.name,
      phone: employee.phone,
      address: employee.address,
    });
  };

  const onDeleteButtonClicked = (employee) => {
    setDeleteDialog({
      show: true,
      name: employee.name,
      id: employee.id,
    });
  };

  const getData = async (token, limit) => {
    var result = await ApiGet(
      dispatch,
      "Employees?limit=" + limit + "&token=" + (token == null ? "" : token)
    );
    if (result != null) {
      setRows(result.data.items.map((x) => createRow(x)));
      setPagination({
        nextToken: result.data.nextToken,
        previousToken: result.data.previousToken,
        currentPage: result.data.currentPage,
        totalPages: result.data.totalPages,
        totalItems: result.data.totalItemCount,
      });
    }
  };

  useEffect(() => {
    getData(null, rowsPerPage);
  }, []);

  const handlePageChanged = async (v) => {
    if (v && pagination.nextToken != null) {
      await getData(pagination.nextToken, rowsPerPage);
    } else if (!v && pagination.previousToken != null) {
      await getData(pagination.previousToken, rowsPerPage);
    }
  };

  const handleChangeRowsPerPage = async (v) => {
    setRowsPerPage(v);
    await getData(null, v);
  };

  const addClicked = async () => {
    var result = await ApiPost(dispatch, "Employees", {
      name: addDialog.name,
      phone: addDialog.phone,
      address: addDialog.address,
    });
    if (result) {
      setAddDialog((prv) => ({ ...prv, show: false }));
      getData(null, rowsPerPage);
    }
  };

  const editClicked = async () => {
    var result = await ApiPut(dispatch, "Employees/" + editDialog.id, {
      name: editDialog.name,
      phone: editDialog.phone,
      address: editDialog.address,
    });

    if (result) {
      setEditDialog((prv) => ({ ...prv, show: false }));
      getData(null, rowsPerPage);
    }
  };

  const deleteConfirmed = async () => {
    var result = await ApiDelete(dispatch, "Employees/" + deleteDialog.id);
    if (result) {
      setDeleteDialog((prv) => ({ ...prv, show: false }));
      getData(null, rowsPerPage);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        sx={{ margin: "5px" }}
        onClick={() =>
          setAddDialog({ show: true, name: "", phone: null, address: null })
        }
      >
        Add
      </Button>
      <AddEmployee
        show={addDialog.show}
        onNameValueChange={(value) =>
          setAddDialog((prv) => ({ ...prv, name: value }))
        }
        onPhoneValueChange={(value) =>
          setAddDialog((prv) => ({ ...prv, phone: value }))
        }
        onAddressValueChange={(value) =>
          setAddDialog((prv) => ({ ...prv, address: value }))
        }
        close={() => setAddDialog((prv) => ({ ...prv, show: false }))}
        add={addClicked}
      />
      <EditEmployee
        show={editDialog.show}
        data={editDialog}
        onNameValueChange={(value) =>
          setEditDialog((prv) => ({ ...prv, name: value }))
        }
        onPhoneValueChange={(value) =>
          setEditDialog((prv) => ({ ...prv, phone: value }))
        }
        onAddressValueChange={(value) =>
          setEditDialog((prv) => ({ ...prv, address: value }))
        }
        close={() => setEditDialog((prv) => ({ ...prv, show: false }))}
        edit={editClicked}
      />
      <DeleteDialog
        name={deleteDialog.name}
        show={deleteDialog.show}
        onDeleteClicked={deleteConfirmed}
        close={() => setDeleteDialog((prv) => ({ ...prv, show: false }))}
      />
      <DataTable
        columns={columns}
        rows={rows}
        pagination={pagination}
        rowsPerPage={rowsPerPage}
        handleChangeRowsPerPage={(v) => handleChangeRowsPerPage(v)}
        handlePageChanged={(v) => handlePageChanged(v)}
      />
    </>
  );
}
