import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import Home from "../views/home/Home";
import ShiftManager from "../views/shiftManager/ShiftManager";
import Machines from "../views/shiftManager/machines/Machines";
import FuelTypes from "../views/shiftManager/fuelTypes/FuelTypes";
import Employees from "../views/shiftManager/employees/Employees";
import Settings from "../views/settings/Settings";
import Users from "../views/settings/users/Users";
import { IsLoggedIn } from "../utils/AuthUtils";
import FuelPrices from "../views/shiftManager/fuelPrices/FuelPrices";
import Accounting from "../views/accounting/Accounting";
import Account from "../views/accounting/Account";
import Labels from "../views/shiftManager/labels/Labels";

export default function PrivateRoutes() {
  return (
    <>
      <Routes>
        <Route element={<RequireLogin />}>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/Shift-Manager" element={<ShiftManager />} />
          <Route exact path="/Shift-Manager/Machines" element={<Machines />} />
          <Route exact path="/Shift-Manager/Fuel-Types" element={<FuelTypes />} />
          <Route exact path="/Shift-Manager/Fuel-Prices" element={<FuelPrices />} />
          <Route exact path="/Shift-Manager/Employees" element={<Employees />} />
          <Route exact path="/Shift-Manager/Labels" element={<Labels />} />
          <Route exact path="/Settings" element={<Settings />} />
          <Route exact path="/Settings/Users" element={<Users />} />
          <Route exact path="/Accounting" element={<Accounting />} />
          <Route exact path="/Accounting/:id" element={<Account />} />
        </Route>
      </Routes>
    </>
  );
}

const RequireLogin = () => {
  if (IsLoggedIn()) {
    return <Outlet />;
  }
  return <Navigate to="/login" />;
};
