import "./App.css";
import Messages from "./components/layout/Messages";
import MenuAppBar from "./components/layout/MenuAppBar";
import { BrowserRouter } from "react-router-dom";
import PrivateRoutes from "./routes/PrivateRoutes";
import PublicRoutes from "./routes/PublicRoutes";
import { StyledEngineProvider } from "@mui/material/styles";

function App() {
  return (
    <div>
      <StyledEngineProvider injectFirst>
        <Messages />
        <BrowserRouter>
          <PublicRoutes />
          <MenuAppBar>
            <PrivateRoutes />
          </MenuAppBar>
        </BrowserRouter>
      </StyledEngineProvider>
    </div>
  );
}

export default App;
