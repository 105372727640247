import React, { useEffect, useState } from "react";
import DataTable from "../../../components/data/DataTable";
import { Button, IconButton, Link, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { ApiDelete, ApiGet, ApiPost } from "../../../services/ApiService";
import { pushMessage } from "../../../features/messages";
import { setTitle } from "../../../features/title";
import DeleteDialog from "../../../components/data/DeleteDialog";
import AddMachine from "./AddMachine";
import DeleteIcon from "@mui/icons-material/Delete";
import Nozzles from "./Nozzles";
import AddNozzle from "./AddNozzle";
import { CreateLinkTitle } from "../../../utils/TitleUtils";

const columns = [
  { id: "name", label: "Machine", align: "center", minWidth: 170 },
  { id: "nozzles", label: "Nozzles", align: "center", minWidth: 100 },
  {
    id: "delete",
    label: "Delete",
    minWidth: 170,
    align: "center",
  },
];

export default function Machines() {
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pagination, setPagination] = useState({
    nextToken: null,
    previousToken: null,
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
  });
  const [deleteDialog, setDeleteDialog] = useState({
    show: false,
    name: "",
    id: "",
  });
  const [addDialog, setAddDialog] = useState({
    show: false,
    name: "",
  });
  const [nozzleDialog, setNozzleDialog] = useState({
    show: false,
    name: "",
    machineId: "",
    nozzles: [],
  });
  const [deleteNozzleDialog, setDeleteNozzleDialog] = useState({
    machineName: "",
    machineId: "",
    nozzle: "",
    show: false,
  });
  const [nozzleAddDialog, setNozzleAddDialog] = useState({
    machine: "",
    machineId: "",
    name: "",
    fuelType: "",
    show: false,
    fuelTypes: [],
  });

  const dispatch = useDispatch();
  dispatch(
    setTitle(CreateLinkTitle("/Shift-Manager", "Shift Manager", "Machines"))
  );

  const createRow = (machine) => {
    var nozzleButton = (
      <Button variant="outlined" onClick={() => showNozzles(machine)}>
        Nozzles
      </Button>
    );
    var deleteButton = (
      <IconButton onClick={() => onDeleteButtonClicked(machine)}>
        <DeleteIcon color="error" />
      </IconButton>
    );
    return { name: machine.name, nozzles: nozzleButton, delete: deleteButton };
  };

  const getData = async (token, limit) => {
    var result = await ApiGet(
      dispatch,
      "Machines?limit=" + limit + "&token=" + (token == null ? "" : token)
    );
    if (result != null) {
      setRows(result.data.items.map((x) => createRow(x)));
      setPagination({
        nextToken: result.data.nextToken,
        previousToken: result.data.previousToken,
        currentPage: result.data.currentPage,
        totalPages: result.data.totalPages,
        totalItems: result.data.totalItemCount,
      });
    }
  };

  const showNozzles = async (machine) => {
    var result = await ApiGet(dispatch, "Machines/" + machine.id);
    if (result != null) {
      setNozzleDialog({
        show: true,
        name: machine.name,
        machineId: machine.id,
        nozzles: result.data.nozzles.map((n) => {
          return {
            name: n.name,
            fuelTypeName: n.fuelTypeName,
            delete: (
              <IconButton
                onClick={() => {
                  setNozzleDialog((prv) => ({ ...prv, show: false }));
                  setDeleteNozzleDialog({
                    show: true,
                    machineName: machine.name,
                    machineId: machine.id,
                    name: n.name,
                  });
                }}
              >
                <DeleteIcon color="error" />
              </IconButton>
            ),
          };
        }),
      });
    }
  };

  const nozzleDeleteConfirmed = async () => {
    var result = await ApiDelete(
      dispatch,
      "Machines/" +
        deleteNozzleDialog.machineId +
        "/Nozzles/" +
        deleteNozzleDialog.name
    );
    if (result) {
      setDeleteNozzleDialog((prv) => ({ ...prv, show: false }));
      getData(null, rowsPerPage);
      showNozzles({
        id: deleteNozzleDialog.machineId,
        name: deleteNozzleDialog.machineName,
      });
    }
  };

  useEffect(() => {
    getData(null, rowsPerPage);
  }, []);

  const handlePageChanged = async (v) => {
    if (v && pagination.nextToken != null) {
      await getData(pagination.nextToken, rowsPerPage);
    } else if (!v && pagination.previousToken != null) {
      await getData(pagination.previousToken, rowsPerPage);
    }
  };

  const handleChangeRowsPerPage = async (v) => {
    setRowsPerPage(v);
    await getData(null, v);
  };

  const onDeleteButtonClicked = (machine) => {
    setDeleteDialog({
      show: true,
      name: machine.name,
      id: machine.id,
    });
  };

  const deleteConfirmed = async () => {
    var result = await ApiDelete("Machines/" + deleteDialog.id);
    if (result) {
      setDeleteDialog((prv) => ({ ...prv, show: false }));
      getData(null, rowsPerPage);
    }
  };

  const addClicked = async () => {
    var result = await ApiPost(dispatch, "Machines", { name: addDialog.name });
    if (result) {
      setAddDialog((prv) => ({ ...prv, show: false }));
      getData(null, rowsPerPage);
    }
  };

  const getFuelTypes = async () => {
    var fuelTypes = [];
    var result = await ApiGet(dispatch, "FuelTypes?limit=" + 100);
    if (result) {
      fuelTypes = result.data.items;
    }
    return fuelTypes;
  };

  const showNozzleAddDialog = async () => {
    var fuelTypes = await getFuelTypes();
    setNozzleAddDialog({
      show: true,
      machine: nozzleDialog.name,
      machineId: nozzleDialog.machineId,
      fuelTypes: fuelTypes,
      fuelType: "",
      name: "",
    });

    setNozzleDialog((prv) => ({ ...prv, show: false }));
  };

  const onNozzleAddClicked = async () => {
    var result = await ApiPost(
      dispatch,
      "Machines/" + nozzleAddDialog.machineId + "/Nozzles",
      {
        nozzle: nozzleAddDialog.name,
        fuelTypeId: nozzleAddDialog.fuelType,
      }
    );
    if (result) {
      setNozzleAddDialog((prv) => ({ ...prv, show: false }));
      showNozzles({
        id: nozzleAddDialog.machineId,
        name: nozzleAddDialog.machine,
      });
    }
  };

  return (
    <>
      <Button
        variant="contained"
        sx={{ margin: "5px" }}
        onClick={() => setAddDialog({ show: true, name: "" })}
      >
        Add
      </Button>
      <AddMachine
        show={addDialog.show}
        onValueChange={(value) =>
          setAddDialog((prv) => ({ ...prv, name: value }))
        }
        close={() => setAddDialog((prv) => ({ ...prv, show: false }))}
        add={addClicked}
      />
      <DeleteDialog
        name={deleteDialog.name}
        show={deleteDialog.show}
        onDeleteClicked={deleteConfirmed}
        close={() => setDeleteDialog((prv) => ({ ...prv, show: false }))}
      />

      <DeleteDialog
        name={
          deleteNozzleDialog.name +
          " of machine " +
          deleteNozzleDialog.machineName
        }
        show={deleteNozzleDialog.show}
        onDeleteClicked={nozzleDeleteConfirmed}
        close={() => setDeleteNozzleDialog((prv) => ({ ...prv, show: false }))}
      />

      <Nozzles
        machine={nozzleDialog.name}
        show={nozzleDialog.show}
        close={() => setNozzleDialog({ show: false, name: "", nozzles: [] })}
        rows={nozzleDialog.nozzles}
        add={showNozzleAddDialog}
      />
      <AddNozzle
        show={nozzleAddDialog.show}
        machine={nozzleAddDialog.machine}
        fuelTypes={nozzleAddDialog.fuelTypes}
        close={() => {
          setNozzleAddDialog((prv) => ({ ...prv, show: false }));
          showNozzles({
            id: nozzleAddDialog.machineId,
            name: nozzleAddDialog.machine,
          });
        }}
        onNameChange={(value) =>
          setNozzleAddDialog((prv) => ({ ...prv, name: value }))
        }
        onFuelTypeChange={(value) =>
          setNozzleAddDialog((prv) => ({ ...prv, fuelType: value }))
        }
        add={onNozzleAddClicked}
      />
      <DataTable
        columns={columns}
        rows={rows}
        pagination={pagination}
        rowsPerPage={rowsPerPage}
        handleChangeRowsPerPage={(v) => handleChangeRowsPerPage(v)}
        handlePageChanged={(v) => handlePageChanged(v)}
      />
    </>
  );
}
