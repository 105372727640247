import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const defaultDataValue = {
  name: null,
  type: null,
  value: null,
};

export default function ModifyLabelValueDialog(props) {
  const [data, setData] = useState(defaultDataValue);

  useEffect(() => {
    setData(defaultDataValue);
  }, [props.show]);

  useEffect(() => {
    setData((prv) => ({ ...prv, type: props.type }));
  }, [props.type]);

  useEffect(() => {
    if (
      props.data?.name != data.name ||
      props.data?.type != data.type ||
      props.data.value != data.value
    ) {
      props.onChange(data);
    }
  }, [data]);

  useEffect(() => {
    setData({
      name: props.data?.name,
      type: props.data?.type,
      value: props.data?.value,
    });
  }, [props.data]);

  return (
    <Dialog open={props.show}>
      <DialogContent sx={{ backgroundColor: "#B4ADA4" }}>
        <Typography>{data.name}</Typography>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Value"
          fullWidth
          type="number"
          variant="standard"
          value={data.value}
          onChange={(e) =>
            setData((prv) => ({ ...prv, value: e.target.value }))
          }
        />
      </DialogContent>
      <DialogActions sx={{ backgroundColor: "#B4ADA4" }}>
        <Button variant="contained" onClick={props.close}>
          Cancel
        </Button>
        <Button variant="contained" onClick={props.delete} color="error">
          Delete
        </Button>
        <Button variant="contained" color="success" onClick={props.save}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
