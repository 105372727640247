import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setTitle } from "../../../features/title";
import { CreateLinkTitle } from "../../../utils/TitleUtils";
import { Button, Grid, IconButton, Paper, Typography } from "@mui/material";
import { ApiDelete, ApiGet, ApiPost } from "../../../services/ApiService";
import DeleteDialog from "../../../components/data/DeleteDialog";
import CloseIcon from "@mui/icons-material/Close";
import AddLabelsDialog from "./AddLabelsDialog";
import { TrendingUpSharp } from "@mui/icons-material";

const defaultAddDialog = {
  name: null,
  type: null,
  show: false,
};

export default function Labels() {
  const [data, setData] = useState(null);
  const [addLabelDialog, setAddLabelDialog] = useState(defaultAddDialog);
  const [deleteDialog, setDeleteDialog] = useState({
    show: false,
    name: "",
    type: "",
  });
  const dispatch = useDispatch();
  dispatch(
    setTitle(CreateLinkTitle("/Shift-Manager", "Shift Manager", "Lables"))
  );

  const getData = async () => {
    var result = await ApiGet(dispatch, "ShiftManager");
    if (result) {
      setData(result.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const onDeleteConfirmed = async () => {
    var result = await ApiDelete(
      dispatch,
      "ShiftManager/" + deleteDialog.type + "/" + deleteDialog.name
    );
    if (result) {
      await getData();
    }
    setDeleteDialog((prv) => ({ ...prv, show: false }));
  };

  const onAddLabelClicked = async () => {
    var result = await ApiPost(dispatch, "ShiftManager", addLabelDialog);
    if (result) {
      await getData();
    }
    setAddLabelDialog(defaultAddDialog);
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={() => setAddLabelDialog((prv) => ({ ...prv, show: true }))}
      >
        Add
      </Button>
      <AddLabelsDialog
        show={addLabelDialog.show}
        close={() => setAddLabelDialog((prv) => ({ ...prv, show: false }))}
        onChange={(val) => setAddLabelDialog((prv) => ({ ...prv, ...val }))}
        add={onAddLabelClicked}
      />
      <DeleteDialog
        name={deleteDialog.name}
        show={deleteDialog.show}
        onDeleteClicked={onDeleteConfirmed}
        close={() => setDeleteDialog((prv) => ({ ...prv, show: false }))}
      />

      <Grid container spacing={2} sx={{ marginTop: "10px" }}>
        <Grid item xs={6}>
          <Paper
            sx={{
              backgroundColor: "#B4ADA4",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {data?.additions?.map((n) => {
              return (
                <Paper
                  sx={{
                    backgroundColor: "#5d7d60",
                    height: "40px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    margin: "10px",
                    padding: "10px",
                    width: "200px",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography color={"white"}>{n}</Typography>
                  <IconButton
                    onClick={() =>
                      setDeleteDialog({
                        name: n,
                        type: "Addition",
                        show: TrendingUpSharp,
                      })
                    }
                  >
                    <CloseIcon />
                  </IconButton>
                </Paper>
              );
            })}
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper
            sx={{
              backgroundColor: "#B4ADA4",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {data?.subtractions?.map((n) => {
              return (
                <Paper
                  sx={{
                    backgroundColor: "#76332f",
                    height: "40px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    margin: "10px",
                    padding: "10px",
                    width: "200px",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography color={"white"}>{n}</Typography>
                  <IconButton
                    onClick={() =>
                      setDeleteDialog({
                        name: n,
                        type: "Subtraction",
                        show: TrendingUpSharp,
                      })
                    }
                  >
                    <CloseIcon />
                  </IconButton>
                </Paper>
              );
            })}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
