import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ApiGet } from "../../services/ApiService";
import { useDispatch } from "react-redux";

export default function AddEntryDialog(props) {
  const [newVehicle, setNewVehicle] = useState(false);
  const [newDriver, setNewDriver] = useState(false);
  const [fuelTypes, setFuelTypes] = useState([]);
  const [data, setData] = useState({
    transactionType: "credit",
    credit: null,
    debit: null,
    driver: null,
    vehicle: null,
    litre: null,
    itemType: null,
    description: null,
  });
  const disptach = useDispatch();

  useEffect(() => {
    setData({
      transactionType: "credit",
      credit: null,
      debit: null,
      driver: null,
      vehicle: null,
      itemType: null,
      description: null,
    });
  }, [props.show]);

  useEffect(() => {
    props.onValueChange(data);
  }, [data]);

  const getFuelTypes = async () => {
    var result = await ApiGet(disptach, "FuelTypes?limit=100");
    if (result != null) {
      setFuelTypes(result.data.items);
    }
  };

  useEffect(() => {
    getFuelTypes();
  }, []);

  return (
    <>
      <Dialog open={props.show}>
        <DialogContent sx={{ backgroundColor: "#B4ADA4" }}>
          <FormControl fullWidth sx={{ marginBottom: "5px" }}>
            <InputLabel id="demo-simple-select-label">Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Account Type"
              value={data.transactionType}
              onChange={(e) =>
                setData((prv) => ({
                  ...prv,
                  transactionType: e.target.value,
                  credit: null,
                  debit: null,
                }))
              }
            >
              <MenuItem value="credit">Credit</MenuItem>
              <MenuItem value="debit">Debit</MenuItem>
            </Select>
          </FormControl>

          {data.transactionType == "credit" && (
            <>
              <TextField
                autoFocus
                type="number"
                margin="dense"
                id="name"
                label="Credit"
                fullWidth
                variant="standard"
                value={data.credit}
                sx={{ marginBottom: "20px" }}
                onChange={(e) =>
                  setData((prv) => ({ ...prv, credit: e.target.value }))
                }
              />
              {props.account?.accountType == "FuelCredit" && (
                <>
                  <Box
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {!newDriver && (
                      <FormControl fullWidth sx={{ marginTop: "5px" }}>
                        <InputLabel id="demo-simple-select-label">
                          Driver
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Driver"
                          value={data.driver}
                          onChange={(e) =>
                            setData((prv) => ({
                              ...prv,
                              driver: e.target.value,
                            }))
                          }
                        >
                          {props.account?.drivers?.map((n) => (
                            <>
                              <MenuItem value={n}>{n}</MenuItem>
                            </>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    {newDriver && (
                      <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Driver"
                        fullWidth
                        variant="standard"
                        value={data.driver}
                        onChange={(e) =>
                          setData((prv) => ({ ...prv, driver: e.target.value }))
                        }
                      />
                    )}

                    <Button
                      sx={{ height: "40px", marginLeft: "5px" }}
                      variant="contained"
                      onClick={() => {
                        setNewDriver((prv) => !prv);
                        setData((prv) => ({ ...prv, driver: null }));
                      }}
                    >
                      {newDriver ? "Old" : "New"}
                    </Button>
                  </Box>

                  <Box
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {!newVehicle && (
                      <FormControl fullWidth sx={{ marginTop: "5px" }}>
                        <InputLabel id="demo-simple-select-label">
                          Vehicle
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Vehicle"
                          value={data.vehicle}
                          onChange={(e) =>
                            setData((prv) => ({
                              ...prv,
                              vehicle: e.target.value,
                            }))
                          }
                        >
                          {props.account?.vehicles?.map((n) => (
                            <>
                              <MenuItem value={n}>{n}</MenuItem>
                            </>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    {newVehicle && (
                      <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Vehicle"
                        fullWidth
                        variant="standard"
                        value={data.vehicle}
                        onChange={(e) =>
                          setData((prv) => ({
                            ...prv,
                            vehicle: e.target.value,
                          }))
                        }
                      />
                    )}

                    <Button
                      sx={{ height: "40px", marginLeft: "5px" }}
                      variant="contained"
                      onClick={() => {
                        setNewVehicle((prv) => !prv);
                        setData((prv) => ({ ...prv, vehicle: null }));
                      }}
                    >
                      {newVehicle ? "Old" : "New"}
                    </Button>
                  </Box>
                  <FormControl
                    fullWidth
                    sx={{ marginTop: "5px", marginBottom: "10px" }}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Item Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Item Type"
                      value={data.itemType}
                      onChange={(e) =>
                        setData((prv) => ({ ...prv, itemType: e.target.value }))
                      }
                    >
                      {fuelTypes.map((n) => (
                        <MenuItem value={n.name}>{n.name}</MenuItem>
                      ))}
                      <MenuItem value={"Oil"}>Oil</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Litre"
                    fullWidth
                    variant="standard"
                    value={data.litre}
                    onChange={(e) =>
                      setData((prv) => ({ ...prv, litre: e.target.value }))
                    }
                  />
                </>
              )}
            </>
          )}
          {data.transactionType == "debit" && (
            <TextField
              type="number"
              autoFocus
              margin="dense"
              id="name"
              label="Debit"
              fullWidth
              variant="standard"
              sx={{ marginBottom: "20px" }}
              value={data.debit}
              onChange={(e) =>
                setData((prv) => ({ ...prv, debit: e.target.value }))
              }
            />
          )}
          <TextField
            sx={{ marginTop: "10px" }}
            label="Description"
            fullWidth
            autoFocus
            multiline
            rows={3}
            margin="dense"
            variant="standard"
            value={data.description}
            onChange={(e) =>
              setData((prv) => ({ ...prv, description: e.target.value }))
            }
          />
        </DialogContent>
        <DialogActions sx={{ backgroundColor: "#B4ADA4" }}>
          <Button variant="contained" onClick={props.close}>
            Cancel
          </Button>
          <Button variant="contained" color="success" onClick={props.add}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
