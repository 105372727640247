import { Grid, Paper, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function ReadingRow(props) {
  const [data, setData] = useState({
    openingReading: props.openingReading,
    test: props.test,
    closingReading: props.closingReading,
    name: props.nozzleName,
    initial: true,
  });

  useEffect(() => {
    if (!data.initial) {
      props.onChange(data);
    }
  }, [data]);

  useEffect(() => {
    setData({
      openingReading: props.openingReading,
      test: props.test,
      closingReading: props.closingReading,
      name: props.nozzleName,
      initial: true,
    });
  }, [props.openingReading, props.test, props.closingReading, props.name]);

  return (
    <>
      <Grid container sx={{ marginBottom: "10px" }}>
        <Grid item xs={2}>
          <Paper
            align="center"
            sx={{
              backgroundColor: props.nozzleColor,
              padding: "10px",
              height: "59px",
            }}
          >
            <Typography color={"white"} fontWeight={"bold"} fontSize={20}>
              {props.nozzleName}
            </Typography>
          </Paper>
        </Grid>
        <Grid align="center" item xs={4}>
          <TextField
            disabled={props.disabled}
            type="number"
            sx={{
              input: { textAlign: "center", fontSize: 20, fontWeight: "bold" },
            }}
            value={data.openingReading}
            onChange={(e) => {
              e.target.value < 0
                ? (e.target.value = 0)
                : setData((prv) => ({
                    ...prv,
                    openingReading: e.target.value,
                    initial: false,
                  }));
            }}
          />
        </Grid>
        <Grid align="center" item xs={2}>
          <TextField
            disabled={props.disabled}
            type="number"
            sx={{
              width: "100px",
              input: { textAlign: "center", fontSize: 20, fontWeight: "bold" },
            }}
            value={data.test}
            onChange={(e) => {
              e.target.value < 0
                ? (e.target.value = 0)
                : setData((prv) => ({
                    ...prv,
                    test: e.target.value,
                    initial: false,
                  }));
            }}
          />
        </Grid>
        <Grid align="center" item xs={4}>
          <TextField
            disabled={props.disabled}
            type="number"
            sx={{
              input: { textAlign: "center", fontSize: 20, fontWeight: "bold" },
            }}
            value={data.closingReading}
            onChange={(e) => {
              e.target.value < 0
                ? (e.target.value = 0)
                : setData((prv) => ({
                    ...prv,
                    closingReading: e.target.value,
                    initial: false,
                  }));
            }}
            error={!!props.error}
          />
        </Grid>
      </Grid>
    </>
  );
}
