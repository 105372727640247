import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, TextField } from "@mui/material";

export default function AddEmployee(props) {
    return (
        <Dialog open={props.show}>
          <DialogContent sx={{ backgroundColor: "#B4ADA4" }}>
            <TextField
              autoFocus
              margin="dense"
              label="Employee Name"
              fullWidth
              variant="standard"
              onChange={(e) => props.onNameValueChange(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              label="Phone"
              fullWidth
              variant="standard"
              onChange={(e) => props.onPhoneValueChange(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              label="Address"
              fullWidth
              variant="standard"
              onChange={(e) => props.onAddressValueChange(e.target.value)}
            />
          </DialogContent>
          <DialogActions sx={{ backgroundColor: "#B4ADA4" }}>
            <Button variant="contained" onClick={props.close}>
              Cancel
            </Button>
            <Button variant="contained" color="success" onClick={props.add}>
              Add
            </Button>
          </DialogActions>
        </Dialog>
      );
}
